/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Divider, Icon, Menu } from 'antd';
import { Link, Redirect, Route } from 'react-router-dom';

import CustomScrollbars from 'util/CustomScrollbars';
import SidebarLogo from './SidebarLogo';

import Auxiliary from 'util/Auxiliary';
import UserProfile from './UserProfile';
import { NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR, NAV_STYLE_NO_HEADER_MINI_SIDEBAR, THEME_TYPE_LITE } from '../../constants/ThemeSetting';
import { USER_ROLES } from '../../constants/Roles';
import { getAuthUser, getLoggedUserInfo } from '../../appRedux/actions/User';
import UserInfo from '../../components/UserInfo';

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    const authUser = props.authUser || getAuthUser();
    this.state = {
      authUser: authUser
    };
  }
  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }
  async componentDidMount() {
    if (this.state.authUser) {
      try {
        let logedUser = await getLoggedUserInfo({ id: this.state.authUser.id });
        logedUser = (logedUser.data && logedUser.data.data) ?logedUser.data.data : (logedUser.data || {});
        await this.setStateAsync({
          authUser: {
            ...this.state.authUser,
            ...logedUser
          }
        });
      } catch (e) {
        console.log(`getLoggedUserInfo`, e);
      }
    }
  }

  getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return 'gx-no-header-notifications';
    }
    return '';
  };

  renderItem = (props) => {
    const { authUser } = this.state;
    if (!authUser || (props.roles && props.roles.indexOf(authUser.role) === -1)) {
      return null;
    }
    return <Menu.Item key={props.key}>{props.children}</Menu.Item>;
  };

  isAllowShowMenuLink = () => {
    const { authUser } = this.state;
    if (!authUser) {
      return false;
    }
    const { useAds, useOrder } = authUser || {};
    if ([false].includes(useOrder)) {
      return false;
    }
    return true;
  };

  isAllowShowReportLink = () => {
    const { authUser } = this.state;
    if (!authUser) {
      return false;
    }
    const { useAds, useOrder } = authUser || {};
    if ([false].includes(useOrder)) {
      return false;
    }
    return true;
  };

  render() {
    const { authUser } = this.state;
    const { themeType, navStyle, pathname } = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[0];
    let currKeySelected = defaultOpenKeys;
    if (!authUser) {
      return null;
    }
    return (
      <Auxiliary>
        <SidebarLogo />
        <div className="gx-sidebar-content">
          <div className={`gx-sidebar-notifications ${this.getNoHeaderClass(navStyle)}`}>
            {/* <UserProfile /> */}
            <UserInfo viewIn={'Sidebar'} />
          </div>
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Menu defaultOpenKeys={[defaultOpenKeys]} defaultSelectedKeys={[defaultOpenKeys]} selectedKeys={[selectedKeys]} theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'} mode="inline">
              {this.renderItem({
                key: 'dashboard',
                // roles: [USER_ROLES.superAdmin],
                children: (
                  <Link to="/dashboard">
                    <i className="icon icon-dasbhoard" />
                    Dashboard
                  </Link>
                )
              })}

              {
                // this.renderItem({
                //   key: "location",
                //   roles: [USER_ROLES.superAdmin],
                //   children: <Link to="/device/group"><i className="icon icon-location"/>Device Group</Link>
                // })
              }

              {this.renderItem({
                key: 'device',
                // roles: [USER_ROLES.superAdmin],
                children: (
                  <Link to="/device">
                    <Icon className="icon" type="tablet" />
                    Device
                  </Link>
                )
              })}
              {this.renderItem({
                key: 'creative',
                children: (
                  <Link to="/creative">
                    <Icon className="icon" type="container" />
                    Library
                  </Link>
                )
              })}
              {this.renderItem({
                key: 'slider',
                children: (
                  <Link to="/slider">
                    <Icon className="icon" type="carry-out" />
                    Slider
                  </Link>
                )
              })}

              {
                // this.renderItem({
                //   key: "menu",
                //   children: <Link to="/menu"><Icon className="icon" type="customer-service"/>Menu</Link>
                // })
              }

              {
                // this.renderItem({
                //   key: "user",
                //   roles: [USER_ROLES.superAdmin],
                //   children: <Link to="/user"><Icon className="icon" type="user"/>User</Link>
                // })
              }

              {/* 
              <Menu.Item key="log">
              <Link to="/log"><Icon className="icon" type="database" />
              Log center</Link>
              </Menu.Item> 
               */}

              {this.renderItem({
                key: 'call-service',
                children: (
                  <Link to="/call-service">
                    <Icon className="icon" type="customer-service" />
                    Call Button
                  </Link>
                )
              })}

              {
                // this.renderItem({
                //   key: "setting",
                //   roles: [USER_ROLES.superAdmin],
                //   children: <Link to="/setting"><Icon className="icon" type="setting"/>Setting</Link>
                // })
              }

              <Menu.SubMenu
                key="menu"
                title={
                  <span>
                    <i className="icon icon-all-contacts" />
                    <span>Menus</span>
                  </span>
                }
                className={!this.isAllowShowMenuLink() ? 'd-none' : ''}
              >
                <Menu.Item key="menu">
                  <Link to="/menu">
                    <span>Menu Items</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="menu/option-set">
                  <Link to="/menu/option-set">
                    <span>Option Set</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="menu/category">
                  <Link to="/menu/category">
                    <span>Category</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="menu/setting">
                  <Link to="/menu/setting">
                    <span>Setting</span>
                  </Link>
                </Menu.Item>
              </Menu.SubMenu>

              <Menu.SubMenu
                key="report"
                title={
                  <span>
                    <i className="icon icon-all-contacts" />
                    <span>Reports</span>
                  </span>
                }
                className={!this.isAllowShowReportLink() ? 'd-none' : ''}
              >
                <Menu.Item key="report/dashboard">
                  <Link to="/report/dashboard">
                    <span>Dashboard</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="report/order">
                  <Link to="/report/order">
                    <span>Orders</span>
                  </Link>
                </Menu.Item>
                {/* <Menu.Item key="report/call-service">
                  <Link to="/report/call-service">
                    <span>Call Service</span>
                  </Link>
                </Menu.Item> */}
              </Menu.SubMenu>

              <Menu.Item key="help-desk-divider" style={{ marginTop: '50px' }}>
                <Divider />
              </Menu.Item>
              <Menu.Item key="report-an-issue">
                <Link target={'_blank'} to={{ pathname: 'https://forms.gle/KcLhVC2ypNkWakhi7' }}>
                  <i className="icon icon-progress" />
                  Report an issue
                </Link>
              </Menu.Item>
              <Menu.Item key="message">
                <Link target={'_blank'} to={{ pathname: 'https://forms.gle/5X2bnwNC7bmX5QeU8' }}>
                  <i className="icon icon-message" />
                  Message
                </Link>
              </Menu.Item>
              <Menu.Item key="help-desk" style={{ marginBottom: '0px' }}>
                <Link to="/">
                  <i className="icon icon-question-circle" />
                  Help desk
                </Link>
              </Menu.Item>
            </Menu>
          </CustomScrollbars>
        </div>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({ settings, auth }) => {
  const { navStyle, themeType, locale, pathname } = settings;
  const { authUser } = auth;
  return { navStyle, themeType, locale, pathname, authUser };
};
export default connect(mapStateToProps)(SidebarContent);
