import React from "react";
import { Spin } from "antd";
import { connect } from "react-redux";
import * as moment from "moment";
// import { Link } from "react-router-dom";
import { getReceiptDetail, downloadReceiptDetail } from "../appRedux/actions/Receipt";
// import IntlMessages from "util/IntlMessages";
import numeral from "numeral";
import { getErrorMessageApi } from "../util/helper";
const imgReceipt = require("./../assets/images/Tabler-with-fusionmenu-good.png");
const logoReceipt = require("./../assets/images/logo_receipt.png");
class ReceiptView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      receiptId: "",
      receiptInfo: null,
      error: "",
      isLoading: false,
    };
  }
  setStateAsync(state) {
    return new Promise(resolve => {
      this.setState(state, resolve);
    });
  }
  componentDidMount() {}
  async handleGetReceipt() {
    if (this.state.isLoading) {
      return;
    }
    if (!this.state.receiptId) {
      console.log(`Missing receiptId`);
      return;
    }
    await this.setStateAsync({ isLoading: true });
    try {
      let rs = await getReceiptDetail(this.state.receiptId);
      await this.setStateAsync({
        receiptInfo: rs.data,
        error: null,
      });
    } catch (e) {
      console.log(`Error getReceiptDetail`, e);
      let msg = getErrorMessageApi(e);
      await this.setStateAsync({ error: msg });
    }
    await this.setStateAsync({ isLoading: false });
  }

  async handlerCancel() {
    await this.setStateAsync({ receiptInfo: null });
  }
  async handlerDownload() {
    console.log(`click download receipt; receiptInfo=`, this.state.receiptInfo);
    if (this.state.receiptInfo && this.state.receiptInfo.id) {
      try {
        await downloadReceiptDetail(this.state.receiptInfo.id);
      } catch (e) {
        console.log(`error download receipt`, e);
      }
    }
  }

  render() {
    let { receiptInfo } = this.state;
    if (this.state.receiptInfo && receiptInfo.id && receiptInfo.items && !this.state.error) {
      return (
        <div className="receipt-view" id="downloadReceipt">
          <div className="container" style={{ background: "#fff", padding: "15px" }}>
            <div className="row">
              <div className="col-md-12">
                <div className="mb-2">
                  <span className="header-label">TAX RECEIPT</span>
                </div>
                <div>
                  <span className="header-label color-black">ORDER TIME:</span>
                  <span className="value-label mr-5 ml-2">{moment(receiptInfo.updatedAt).format("DD/MM/YYYY HH:mm")}</span>
                  <span className="header-label color-black">ORDER NUMBER:</span>
                  <span className="value-label ml-2">{receiptInfo.objectId}</span>
                </div>
                <div>
                  <span className="header-label color-black">AT THE MERCHANT</span>
                  <span className="value-label ml-2">{receiptInfo.shop.companyName}</span>
                </div>

                <div className="mb-3 mt-5">
                  <span className="header-label">PURCHASE DETAILS</span>
                </div>
                <div>
                  <table className=" w-100 table table-bordered">
                    <thead>
                      <tr>
                        <th className=" font-weight-bold">Item detail</th>
                        <th className=" font-weight-bold">Amount</th>
                        <th className=" font-weight-bold">Payment method</th>
                        <th className=" font-weight-bold">Payment Provider</th>
                      </tr>
                    </thead>
                    <tbody>
                      {receiptInfo.items.map((item, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{item.menuItem.title}</td>
                            <td>
                              {item.quantity} x ${item.subTotal}
                            </td>
                            <td>Online EFPOS</td>
                            <td>Provider</td>
                          </tr>
                        );
                      })}
                      {/* <tr>
                        <td>item name</td>
                        <td>5 x $5.5</td>
                        <td>Online EFPOS</td>
                        <td>Provider</td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>

                <div>
                  <span className="header-label">PURCHASE TOTAL:</span>
                  <span className="font-20 ml-2">
                    <span className=" font-weight-bold">{numeral(receiptInfo.total).format("$0,0.00")}</span>
                    <span> includes GST of </span>
                    <span className="font-weight-bold"> {numeral(receiptInfo.GSTAmount).format("$0,0.00")}</span>
                  </span>
                </div>

                <div className="mt-5">
                  <span className="d-block">For all enquiries regarding this order, please contact:</span>
                  <span className="d-block font-bold font-weight-bold">{receiptInfo.shop.companyName}</span>
                  <span className="d-block font-bold font-weight-bold">{receiptInfo.shop.address}</span>
                </div>
                <div className="mt-3">
                  <span>Dikauri team</span>
                  <span className="d-block text-primary">
                    <a href="https://dikauri.com" target={"_blank"} rel="noopener noreferrer">
                      https://dikauri.com
                    </a>
                  </span>
                </div>
              </div>
              <div className="col-md-12 text-center mt-3">
                <button className="btn btn-secondary text-white mr-2" onClick={e => this.handlerCancel()}>
                  Cancel
                </button>
                <button className="btn btn-primary" onClick={e => this.handlerDownload()}>
                  Download
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="receipt-view">
        <div className="container">
          <div className="row" style={{ alignContent: "center", alignItems: "center", justifyContent: "center" }}>
            <div className="d-none d-md-block d-lg-block col-lg-4 col-md-4 d-sm-none d-xs-none text-center left-block">
              <img alt='' src={imgReceipt} style={{ width: "100%" }} className="img-thumbnail" />
              <div className="text-center font-25 font-weight-bold">MINI KIOSK & DISPLAY</div>
            </div>
            <div className="d-none d-md-block d-lg-block col-lg-2 col-md-2"></div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
              <div className="form-search-receipt">
                <div className="img-logo">
                  <img alt='' src={logoReceipt} style={{ width: "100px" }} />
                </div>
                <div className="description text-white mt-4 mb-4 font-15 font-weight-bold">Enter the receipt code then press GET RECEIPT to view your receipt</div>

                {this.state.error ? (
                  <div class="alert alert-danger text-left">
                    <strong>Error!</strong> {this.state.error}
                  </div>
                ) : null}

                <div className="form-group mt-5">
                  <label className="text-black font-20 font-weight-bold">Receipt Code</label>
                  <input
                    type={"text"}
                    placeholder="Receipt code"
                    className="form-control input-code"
                    onChange={async e => {
                      await this.setStateAsync({ receiptId: e.target.value });
                    }}
                    value={this.state.receiptId}
                  />
                </div>
                <div
                  className="form-group text-center mt-5"
                  style={
                    {
                      // marginTop: "50px",
                    }
                  }
                >
                  <button className="btn btn-warning btn-get-receipt text-center" onClick={() => this.handleGetReceipt()}>
                    {this.state.isLoading ? <Spin color={"#fff"} size={"small"} /> : null}
                    <span style={{ color: "#fff" }}>GET RECEIPT</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  // const { token } = auth;
  return {};
};

export default connect(mapStateToProps, {})(ReceiptView);
